<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">  
      <div class="d-flex flex-wrap">
       
      </div>
    </b-media>

    <!-- Module Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Module Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="moduleData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Slug -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Slug"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="moduleData.slug"
            />
          </b-form-group>
        </b-col>

       

      </b-row>
      
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateModule(moduleData._id)"
    >
      Save Changes
    </b-button>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },
  // setup() {
   
  //   const statusOptions = [
  //     { label: 'True', value: true },
  //     { label: 'False', value: false },
  //   ]

  //   return {
  //    statusOptions,
  //   }
  // },
  methods: {
    updateModule(Id) {
      store.dispatch('app-module/updateModule', { id: Id, moduleData: this.moduleData})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Module updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
