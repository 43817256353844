<template>
  <component :is="moduleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="moduleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching module data
      </h4>
      <div class="alert-body">
        No module found with this module id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'modules-list'}"
        >
          Module List
        </b-link>
        for other modules.
      </div>
    </b-alert>

    <b-tabs
      v-if="moduleData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Module</span>
        </template>
        <module-edit-tab-account
          :module-data="moduleData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import moduleStoreModule from '../moduleStoreModule'
import ModuleEditTabAccount from './ModuleEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    ModuleEditTabAccount,    
  },
  setup() {
    const moduleData = ref(null)

    const MODULE_APP_STORE_MODULE_NAME = 'app-module'

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-module/fetchModule', { id: router.currentRoute.params.id })
      .then(response => { moduleData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          moduleData.value = undefined
        }
      })

    return {
      moduleData,
    }
  },
}
</script>

<style>

</style>
